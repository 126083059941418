export const CONFIG = {
  // Base URL
  LLM_WEBSOCKET_URL: process.env.REACT_APP_LLM_WEBSOCKET_URL,
  // Websocket URL
  WEBSOCKET_URL: process.env.REACT_APP_WEBSOCKET_URL ,
  //Google Credentials
  GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,

  LOGIN_URL: '/customer_management/google_login',
  TOKEN_URL: '/customer_management/token/refresh',
  SETTINGS_URL: '/customer_management/settings',
  LOGOUT_URL: '/customer_management/user_logout',
  PDF_UPLOAD_ENDPOINT: '/api/upload_pdf',
  PDF_DOCUMENTS_ENDPOINT: '/api/documents',
  LLM_WEBSOCKET_URL: '/llm/',
  // Chat History URL
  CHAT_HISTORY_URL: '/api/chat_histories/',
  // Login URL
  // LOGIN_URL: process.env.REACT_APP_LOGIN_URL,
  // TOKEN_URL: process.env.REACT_APP_TOKEN_URL,
  // SETTINGS_URL : process.env.REACT_APP_SETTINGS_URL,
  // LOGOUT_URL : process.env.REACT_APP_LOGOUT_URL,
  // // API URL
  API_BASE_URL: process.env.REACT_APP_API_BASE_URL,
  //PDF_UPLOAD_ENDPOINT: process.env.REACT_APP_PDF_UPLOAD_ENDPOINT,
  // PDF_DOCUMENTS_ENDPOINT: process.env.REACT_APP_PDF_DOCUMENTS_ENDPOINT,
}

// Helper function to get full API URLs
export const getApiUrl = (endpoint) => `${CONFIG.API_BASE_URL}${endpoint}`;