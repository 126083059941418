// hooks/useChatHistory.js
//import { useToast } from '@chakra-ui/react';
import { useChat } from '../contexts/ChatContext';
import api from '../services/api';
import { CONFIG } from '../config.js';

export const useChatHistory = () => {
  //const toast = useToast();
  const { updateChatHistories } = useChat();

  const fetchChatHistories = async () => {
    try {
      const response = await api.get(CONFIG.API_BASE_URL + CONFIG.CHAT_HISTORY_URL);
      updateChatHistories(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching chat histories:', error);
      if (error.response) {
        // Server responded with a status other than 200 range
        if (error.response.status !== 401) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
        console.error('Response headers:', error.response.headers);
        }
      } else if (error.request) {
        // Request was made but no response received
        console.error('Request data:', error.request);
      } else {
        // Something else happened in setting up the request
        console.error('Error message:', error.message);
      }
      // toast({
      //   title: 'Error',
      //   description: 'Failed to fetch chat histories.',
      //   status: 'error',
      //   duration: 3000,
      //   isClosable: true,
      // });
       return [];
    }
  };

  return { fetchChatHistories };
};
//   const fetchChatHistories = async () => {
//     try {
//       const response = await api.get('https://localhost:8001/api/chat_histories/');
//       updateChatHistories(response.data);
//       return response.data;
//     } catch (error) {
//       console.error('Error fetching chat histories:', error);
//       toast({
//         title: 'Error',
//         description: 'Failed to fetch chat histories.',
//         status: 'error',
//         duration: 3000,
//         isClosable: true,
//       });
//       throw error;
//     }
//   };

//   return { fetchChatHistories };
// };